<template>
<div class="card mt-4 mb-4">
    <div class="card-header">
        Szablon Service Port
        <div style="float: right">
            <div @click="showModal">
                <i class="fas fa-plus"></i>
            </div>
        </div>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>VLAN</th>
                    <th>User VLAN</th>
                    <th v-if="!this.IsEpon">Gem</th>
                    <th>IPTV</th>
                    <th>Native VLAN</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="sp in oltTemplateServicePortList" v-bind:key="sp.Id">
                    <td>{{ sp.Vlan }}</td>
                    <td>{{ sp.UserVlan }}</td>
                    <td v-if="!this.IsEpon">{{ sp.Gem }}</td>
                    <td>{{ sp.Iptv }}</td>
                    <td>{{ sp.NativePorts }}</td>
                    <td>X</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<router-link :to="`/huawei/olt/${this.$route.params.id}/template`">Powrót</router-link>

<Modal v-show="isModalVisible" @close="closeModal">
    <template v-slot:body>
        <form @submit.prevent="addServicePortTemplate">
            <div class="form-floating mb-3">
                <input class="form-control" id="vlan" type="text" placeholder="VLAN" v-model="Vlan" />
                <label for="vlan">VLAN</label>
            </div>
            <div class="form-floating mb-3">
                <input class="form-control" id="userVlan" type="text" placeholder="User VLAN" v-model="userVlan" />
                <label for="userVlan">User VLAN</label>
            </div>
            <div class="form-floating mb-3" v-if="!IsEpon">
                <input class="form-control" id="gem" type="text" placeholder="GEM" v-model="Gem" />
                <label for="gem">GEM</label>
            </div>
            <div class="mb-3">
            <label class="form-label d-block">Native VLAN Port</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="1" type="checkbox" value="1" v-model="nativeVlanPorts" />
                    <label class="form-check-label" for="1">1</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="2" type="checkbox" value="2" v-model="nativeVlanPorts" />
                    <label class="form-check-label" for="2">2</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="3" type="checkbox" value="3" v-model="nativeVlanPorts" />
                    <label class="form-check-label" for="3">3</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="4" type="checkbox" value="4" v-model="nativeVlanPorts" />
                    <label class="form-check-label" for="4">4</label>
                </div>
            </div>
            <div class="mb-3">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="iptv" type="checkbox" name="iptv" v-bind:true-value="1" v-bind:false-value="0" v-model="Iptv" />
                    <label class="form-check-label" for="iptv">IPTV</label>
                </div>
            </div>
            <div class="d-grid">
                <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Zapisz</button>
            </div>
        </form>
    </template>
</Modal>

</template>

<script>
import Modal from '../../components/Modal.vue'
import axios from 'axios'

export default {
    components: {
      Modal,
    },
    data() {
        return {
            isModalVisible: false,
            Vlan: null,
            userVlan: null,
            Gem: null,
            nativeVlanPorts: [],
            Iptv: 0,
            oltTemplateServicePortList: null,
            IsEpon: null,
        };
    },
    mounted() {
        this.IsEponType(),
        this.getTemplateOltServiceList()
    },
    methods: {
        IsEponType() {
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiOltIsEpon: {
                    OltId: parseInt(this.$route.params.id)
                }
            };
            axios.post("/huawei/olt/isepon", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.IsEpon = result.data.HuaweiOltIsEpon
            })
        },
        getTemplateOltServiceList() {
            this.oltTemplateServicePortList = null
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiGetTemplateServicePortData: {
                    TemplateId: parseInt(this.$route.params.templateid)
                }
            };
            axios.post("/huawei/olt/template/serviceport/data", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.oltTemplateServicePortList = result.data.HuaweiGetTemplateServicePortData
            })
        },
        showModal() {
            this.isModalVisible = true
        },
        closeModal() {
            this.isModalVisible = false
        },
        addServicePortTemplate() {
            const { Vlan, userVlan, Gem, nativeVlanPorts, Iptv } = this
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiAddTemplateServicePortData: {
                    OltId: parseInt(this.$route.params.id),
                    TemplateId: parseInt(this.$route.params.templateid),
                    Vlan: parseInt(Vlan),
                    UserVlan: parseInt(userVlan),
                    Gem: parseInt(this.IsEpon ? -1 : Gem),
                    NativeVlanPorts: nativeVlanPorts,
                    Iptv: parseInt(Iptv)
                }
            }

            axios.post("/huawei/olt/template/serviceport/data/add", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.getTemplateOltServiceList()
                this.closeModal()
            })
        }
    }
}
</script>
